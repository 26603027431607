import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'

class Nav extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      user: JSON.parse(sessionStorage.getItem('user'))
    }
  }

  goBack(){
    var p = this.props.location.pathname.split('/')
    p.pop()
    p.pop()

    var newPath = p.join('/')
    this.props.history.push(newPath)
  }


  render () {
    const shouldGoBack = this.props.location.pathname === '/' ? false : true
    return(
      <div>
        <nav className="bg-gray-300 p-4">
          <ul className="flex space-between">
            <li className="flex-1 mr-2">
              {shouldGoBack ?
                <button
                  onClick={() => this.goBack()}
                  className="text-center block text-left align-middle">
                    <i className="material-icons md-24 inline-block align-middle">arrow_back_ios</i>
                </button> : null }
            </li>

            <li className="font-bold">
              <Label path={this.props.location} {...this.props}/>
            </li>
            <li className="text-right flex-1">
              <Link className="block text-gray-900" to="/">
                <div className="text-center flex justify-end">
                  <img alt="user-logo" style={{maxHeight: '40px'}} src={this.state.user.images?.data.full_url}/>
                </div>
              </Link>
            </li>
          </ul>
        </nav>

      </div>
    )
  }
}

function Label({path, installation}){
  var p = path.pathname.split('/')
  var label = 'Seleccione la instalación'

  if (installation !== null){
    label = installation.display_name
  }


  return (<div className="truncate w-48 text-center">{label}</div>)
}

const mapStateToProps = function (state) {
    return {
        installations: state.installations.installations,
        installation: state.installations.selectedInstallation
    };
};

export default connect(mapStateToProps)(withRouter(Nav));
