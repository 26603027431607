import Login from './views/login'
import Installations from './views/installations'
import Installation from './views/installation'
import Screens from './views/screens'
import Screen from './views/screen'
import Actions from './views/actions'
import Timeline from './views/timeline'

const routes = [
  {
    name: 'Login',
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    name: 'Installations',
    path: '/installations',
    component: Installations,
    exact: false
  },
  {
    name: 'Installation',
    path: '/installation/:installationId',
    component: Installation,
    exact: true
  },
  {
    name: 'Home',
    path: '/',
    component: Installations,
    exact: true
  },
  {
    name: 'Screens',
    path: '/installation/:installationId/screens',
    component: Screens,
    exact: true
  },
  {
    name: 'Screen',
    path: '/installation/:installationId/screen/:screenId',
    component: Screen,
    exact: true
  },
  {
    name: 'Actions',
    path: '/installation/:installationId/screen/:screenId/action/:actionId',
    component: Actions,
    exact: true
  },
  {
    name: 'Timeline',
    path: '/installation/:installationId/screen/:screenId/timeline/:timelineId',
    component: Timeline,
    exact: true
  },
]

export default routes;
