import React from 'react';
import { Switch, Route } from "react-router-dom";

import PrivateContent from './PrivateContent'
import CreateForm from './views/create-account'
import RequestRestPassword from './views/request-reset-password/RequestResetPassword'
import Login from './views/login'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">

      {/* Switch it like its hot */}
      <Switch>
       <Route path="/set-password" exact={true}><CreateForm /></Route>
       <Route path="/request-reset-password" exact={true}><RequestRestPassword /></Route>
       <Route path="/login" exact={true }><Login /></Route>
       <Route path="/" exact={false}><PrivateContent /></Route>
      </Switch>


      <ToastContainer />
    </div>
  );
}

export default App;
