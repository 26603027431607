import React from 'react'
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux'
import Loader from '../../components/Loader/Loader1'
import axios from 'axios'
import host from '../../const/host'


class Installation extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      loading: false,
      error: null
    }
  }
  componentDidMount(){
    this.getInstallation()
    this.getScreens()
  }

  getInstallation(){
    const { installationId } = this.props.match.params
    const { installations } = this.props

    var selectedInstallation = null
    var index = installations.findIndex(d => d.id == installationId)

    if (index !== -1) {
      selectedInstallation = installations[index]
    } else {
      this.getOneInstallation(installationId)
    }

    this.props.dispatch({type: 'SELECT_INSTALLATION', payload: selectedInstallation})
  }

  async getOneInstallation(id){
    try { 
      const res = await axios.get(`${host}/items/installation/${id}`, {withCredentials: true})
      const installation = res.data.data

      this.props.dispatch({type: 'SELECT_INSTALLATION', payload: installation})
    } catch (err) {
      console.log(err)
    }
  }

  async getScreens(){
    if (this.props.screens.length !== 0) return;

    this.setState({loading: true})
    try { 
      const { installationId } = this.props.match.params
      const res = await axios.get(`${host}/items/screen?filter[installation_owner]=${installationId}&fields=*,possible_macros.*,timelines.*`, {withCredentials: true})
      const screens = res.data.data

      // console.log(screens)

      this.props.dispatch({type: 'SET_SCREENS', payload: screens})
      this.setState({loading: false})
    } catch (err) {
      this.setState({loading: false, error: err.message})
    }
  }

  render () {
    const { installationId } = this.props.match.params
    const { screens } = this.props
    const { loading } = this.state

    return (
      <>
        {loading ?
          <Loader />
        :
          <div className="flex flex-wrap text-center pt-8">
            {screens.map((d, i) =>
              <div key={d.id + d.display_name} className="w-full p-4 pb-0">
                <Link to={`/installation/${installationId}/screen/${d.id}`} className="inline-block text-center bg-gray-300 shadow w-full hover:shadow-lg text-black font-bold py-3 p-4 pb rounded-full">
                  <p>{d.display_name}</p>
                </Link>
                <div className="text-center p-2">
                  <small className="text-gray-600 text-light block truncate w-full">{d.comment}</small>
                </div>
              </div>
            )}
            {screens.length === 0 && !loading ?
                <p className="pt-20 p-6 w-full text-3xl text-gray-500 text-center">No hay entradas todavía.</p>
                : null}

          </div>
        }
      </>
    )
  }
}

const mapStateToProps = function (state) {
    return {
        installations: state.installations.installations,
        screens: state.installations.screens
    };
};

export default connect(mapStateToProps)(withRouter(Installation));
