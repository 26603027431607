import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import host from '../../const/host'

class Installations extends React.Component {
  componentDidMount(){
    const { installations } = this.props
    this.props.dispatch({type: 'SELECT_INSTALLATION', payload: null})
    this.props.dispatch({type: 'SET_SCREENS', payload: []})

    if (installations.length === 0) {
      this.getInstallations()
    }
  }

  async getInstallations() {
    try { 
      const res = await axios.get(`${host}/items/installation`, {withCredentials: true})
      const installations = res.data.data

      this.props.dispatch({type: 'INSERT_INSTALLATION', payload: installations})
    } catch (err) {
      console.log(err)
    }
  }

  render () {
    const { installations } = this.props

    return (
        <div className="flex flex-wrap pt-8">
          {installations.map((d, i) =>
            <div key={d.id + d.display_name} className="w-full p-4">
              <Link to={`/installation/${d.id}`} className="inline-block text-center bg-gray-300 shadow-lg w-full hover:shadow-4xl text-black font-bold py-3 p-4 rounded-full">
                <p>{d.display_name}</p>
              </Link>
            </div>
          )}
        </div>
    )
  }
}

const mapStateToProps = function (state) {
    return {
        installations: state.installations.installations,
    };
};

export default connect(mapStateToProps)(Installations);
