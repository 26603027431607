import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import host from '../../const/host'
import { toast } from 'react-toastify';

import Wheelpicker from '../../components/Wheelpicker'

class Actions extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      checked: false
    }
  }

  componentDidMount(){
    this.checkScreen()
  }

  checkScreen(){
    const { screenId, installationId } = this.props.match.params
    const { screens } = this.props
    const screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null
    if (screen === null) {
      this.props.history.push(`/installation/${installationId}`)
    }
  }

  toggleMacro(){
    const { screenId } = this.props.match.params
    const { screens } = this.props
    var screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null

    if (screen === null) return

    screen.timeline_active = !screen.timeline_active
    this.updateScreen(screen)
  }

  selectMacro(id){

    const { screenId } = this.props.match.params
    const { screens } = this.props
    var screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null

    if (screen === null) return

    screen.immidiate_action_macro = {id: id}
    this.updateScreen(screen)
  }

  selectOptionMacro(id){
    const { screenId, installationId } = this.props.match.params
    const { screens } = this.props
    var screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null

    if (screen === null) return

    this.setState({checked: false})

    screen.immidiate_action_macro = {id: id}
    this.props.dispatch({type: 'UPDATE_SCREEN', payload: screen})
  }

  async updateScreen(obj){
    const data = {
      immidiate_action_macro: obj.immidiate_action_macro,
      timeline_active: obj.timeline_active
    }
    try {
      await axios.patch(`${host}/items/screen/${obj.id}`, data, {withCredentials: true})
      this.props.dispatch({type: 'UPDATE_SCREEN', payload: obj})
      toast.success("éxito")
    } catch(err) {
      console.log(err)
      alert(err.message)
    }
  }

  toggleSlider(){
    const { screenId } = this.props.match.params
    const { screens } = this.props
    var screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null

    // if ( this.state.checked ) return
    if (!window.confirm("Por favor, confirme")) {
      return
    }

    this.setState({checked: !this.state.checked})
    this.updateScreen(screen)
  }


  render () {
    const { screenId, installationId } = this.props.match.params
    const { checked } = this.state
    const { screens } = this.props
    const screen = screens.length !== 0 ? screens.find(s => s.id == screenId) : null
    const checkedTl = screen !== null ? screen.timeline_active : false
    const selectedMacro = screen && screen.immidiate_action_macro ? screen.possible_macros.findIndex(d => d.id === screen.immidiate_action_macro.id) : 0

    if (screen === null) return (<></>)


    return (
      <div className="flex flex-wrap justify-center text-center">
        <p className="pt-10 p-6 w-full text-3xl text-gray-500 text-center">{screen && screen.display_name} Macros</p>
        <div className="w-full p-4">
          <div className="flex align-center justify-between text-center mb-10 bg-gray-300 shadow-lg w-full text-black py-3 px-4 p-xs-10 p-4 rounded-lg">
            <div className="flex items-center">
              <p>Habilitar el programa</p>
            </div>
            <div>
              <label htmlFor="checkbox" className="mt-1 inline-flex items-center cursor-pointer">
                <span className="relative">
                  <span className="block w-16 h-8 bg-gray-400 rounded-full shadow-inner"></span>
                  <span
                    style={checkedTl ? {'--transform-translate-x': '130%'} : {}}
                    className={`absolute block w-6 h-6 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${checkedTl ? 'bg-green-500 transform' : 'bg-white'}`}>
                    <input onClick={() => this.toggleMacro()} id="checkbox" type="checkbox" className="absolute opacity-0 w-0 h-0" />
                  </span>
                </span>
              </label>
            </div>
          </div>

          <div className="flex align-center flex-wrap justify-between text-center bg-white shadow-lg w-full text-black py-3 px-4 p-xs-10 p-4 rounded-lg">
            <div className="w-full">
              <div className="flex justify-center">
                <div className="w-full sm:w-64 px-3 mb-6 md:mb-0 m-w-40">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 mt-4">
                    Selecciona la macro
                  </label>
                  <div className="relative">
                    <select
                      onChange={(e) => this.selectOptionMacro(e.target.value)}
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                      {screen?.possible_macros.map((d, i) =>
                        <option key={'m-' + i} value={d.id}>{d.display_name}</option>
                      )}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*<div className="w-full">
              <Wheelpicker selected={selectedMacro} onChange={(id) => this.selectOptionMacro(id)} data={screen?.possible_macros}/>
            </div>*/}

            <div className="w-full mt-10">
              <label htmlFor="check" className="mt-1 inline-flex items-center cursor-pointer">
                <button onClick={() => this.toggleSlider()} className="bg-transparent hover:bg-gray-700 text-gray-700 font-semibold hover:text-white mb-6 py-2 px-4 border border-gray-700 hover:border-transparent rounded">
                  APLICAR
                </button>
                {/*<span className="relative">
                  <span className="block w-64 h-12 bg-gray-400 rounded-full shadow-inner"></span>
                  <span
                    style={checked ? {'--transform-translate-x': '93%'} : {}}
                    className={`absolute block w-32 h-10 mt-1 ml-1 rounded-full shadow inset-y-0 left-0 focus-within:shadow-outline transition-transform duration-300 ease-in-out ${checked ? 'bg-green-500 transform' : 'bg-white'}`}>
                    <input onClick={() => this.toggleSlider()} id="check" type="checkbox" className="absolute opacity-0 w-0 h-0" />
                    <span className={`${!checked ? 'text-gray-600' : 'text-white'} block mt-2`}>APLICAR</span>
                  </span>
                </span>*/}
              </label>
            </div>

          </div>

        </div>


      </div>
    )
  }
}

const mapStateToProps = function (state) {
    return {
        installations: state.installations.installations,
        screens: state.installations.screens
    };
};

export default connect(mapStateToProps)(withRouter(Actions));
