import React from 'react'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import Dots from '../login/loadingDots'
import host from '../../const/host'

class RequestRestPassword extends React.Component {

  constructor(props){
    super(props)
    this.email = React.createRef();
    this.state = {
      error: null,
      loading: false,
      logo: '',
      success: null
    }
  }

  componentDidMount(){
    this.getLogo()
    console.log(this.props)
    console.log(window.location)
  }

  async getLogo(){
    try {
      const res = await axios.get(`${host}/`, {withCredentials: true})
      const logo = res.data.data.api.project_logo.full_url
      this.setFavicon(logo)
      this.setState({logo: logo})
    } catch(err){
      console.log(err)
    }
  }

  setFavicon(icon){
    document.getElementById('fav-1').href = icon
    document.getElementById('fav-2').href = icon
    document.getElementById('fav-3').href = icon
    document.getElementById('fav-4').href = icon
    document.getElementById('fav-5').href = icon
  }

  async submitPasswordResetRequest(){
    const email = this.email.current.value
    this.setState({error: null, loading: true})

    if (email === '') {
      this.setState({error: 'You need to enter a valid email'})
      return
    }

    try {
      const req = {
        email: email,
        reset_url: `${window.location.origin}/set-password`,
      }

      console.log(req)

      const res = await axios.post(`${host}/auth/password/request`, req, {withCredentials: true})
      this.setState({success: 'Reset E-Mail was successully delivered.'})
    } catch (err){
      this.setState({error: 'Something went wrong.', loading: false})
      console.log(err.message)
    }
  }


  render () {
    const { error, loading, logo, success } = this.state
    return (
      <div className="p-2 flex justify-center content-center flex-wrap bg-gray-800 h-screen">
        {!success ?<div className="w-full max-w-xs">
          <div  className="flex justify-center p-4 pt-0">
            <img style={{maxHeight: '50px'}} src={logo}/>
          </div>
          <form autoComplete="off" onSubmit={() => this.submitPasswordResetRequest()} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <p className="text-xl text-center text-gray-700 font-bold mb-6">Reset Password</p>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                E-Mail
              </label>
              <input autoComplete="off" required ref={this.email} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="mail" type="email" name="pw-1" placeholder="name@company.com" />
              {error ? <p className="text-red-500 text-xs italic">{error}</p> : null }
            </div>

            <div className="flex items-center justify-center">
              <button
                disabled={loading}
                onClick={() => this.submitPasswordResetRequest()}
                className="custom bg-blue-500 disabled:bg-gray-300 h-10 w-40 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm" type="button">
                {!loading ? 'Reset Password' : <Dots /> }
              </button>
            </div>
          </form>
          <p className="text-center text-gray-500 text-xs">
            &copy;{moment().format('YYYY')} led&go. All rights reserved.
          </p>
        </div> :
          <div  className="flex flex-col text-center justify-center p-4 pt-0 text-center">
            <div className="w-full text-center">
              <img style={{maxHeight: '50px', margin: '0 auto'}} src={logo}/>
            </div>
            <div className="w-full text-center">
              <h2 className="text-2xl text-white text-center">{success}</h2>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(RequestRestPassword);
