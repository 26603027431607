import React from 'react'
import axios from 'axios'
import host from '../../const/host'
import { withRouter } from 'react-router-dom'

class Footer extends React.Component {
  constructor(){
    super()
    this.state = {
      logo : ''
    }
  }
  componentDidMount(){
    this.getLogo()
  }

  async getLogo(){
    try {
      const res = await axios.get(`${host}/`, {withCredentials: true})
      const logo = res.data.data.api.project_logo.full_url
      this.setState({logo: logo})
    } catch(err){
      console.log(err)
    }
  }

  async logout(){
    try {
      await axios.post(`${host}/auth/logout`, {withCredentials: true})
      sessionStorage.removeItem('user')
      this.props.history.push('/login')
    } catch(err){
      console.log(err)
    }
  }

  render () {
    const { logo } = this.state
    return (
      <footer className="w-full justify-center p-2">
        <ul className="flex space-between">
          <li className="flex-1 mr-2">
          </li>

          <li className="">
            <i style={{paddingBottom: '6px'}} className="inline-block align-bottom">powered by </i>
            {logo !== '' ? <img className="inline ml-2" alt="logo" style={{maxHeight: '36px'}} src={logo}/> : null }
          </li>
          <li className="text-right flex-1">
            <p className="block mr-6" onClick={() => this.logout()} style={{textDecoration: 'underline', display: 'block', cursor: 'pointer'}}>
              logout
            </p>
          </li>
        </ul>
      </footer>
    )
  }
}

export default withRouter(Footer);
