import React from 'react'
import axios from 'axios'
import { withRouter, Link } from 'react-router-dom'
import moment from 'moment'
import Dots from './loadingDots'
import host from '../../const/host'


class LoginForm extends React.Component {
  constructor(props){
    super(props)
    this.email = React.createRef();
    this.password = React.createRef();
    this.state = {
      error: null,
      loading: false,
      logo: ''
    }
  }

  componentDidMount(){
    this.getLogo()
  }

  async getLogo(){
    console.log(host)
    try {
      const res = await axios.get(`${host}/`, {withCredentials: true})
      const logo = res.data.data.api.project_logo.full_url
      this.setFavicon(logo)
      this.setState({logo: logo})
    } catch(err){
      console.log(err)
    }
  }

  setFavicon(icon){
    document.getElementById('fav-1').href = icon
    document.getElementById('fav-2').href = icon
    document.getElementById('fav-3').href = icon
    document.getElementById('fav-4').href = icon
    document.getElementById('fav-5').href = icon
  }


  async login(){
    const pw = this.password.current.value
    const email = this.email.current.value
    console.log(`${host}/auth/authenticate`)

    if (email === ''){
      this.setState({error: 'You need to enter a valid email address.'})
      return
    }

    if (pw === ''){
      this.setState({error: 'You need to enter a valid password.'})
      return
    }

    this.setState({loading: true})

    try {
      var images = null
      const req = {
        email: email,
        password: pw,
        mode: 'cookie'
      }

      await axios.post(`${host}/auth/logout`, {withCredentials: true})
      const res = await axios.post(`${host}/auth/authenticate`, req, {withCredentials: true})

      if (res.data.data.user.avatar !== null) {
        images = await axios.get(`${host}/files/${res.data.data.user.avatar}`, { withCredentials: true})
      }

      const user = { user: res.data.data, images: images?.data.data}
      console.log(user)

      this.setState({error: null, loading: false})

      sessionStorage.setItem('user', JSON.stringify(user))

      this.props.history.push('/')
    } catch (err){
      this.setState({error: 'Wrong email or password.', loading: false})
      console.log(err.message)
    }
  }

  render () {
    const { error, loading, logo } = this.state
    return(
      <div className="w-full max-w-xs">
        <div  className="flex justify-center p-4 pt-0">
          <img style={{maxHeight: '50px'}} src={logo}/>
        </div>
        <form onSubmit={() => this.login()} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <p className="text-xl text-center text-gray-700 font-bold mb-6">Login</p>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input required ref={this.email} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Username" />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input required ref={this.password} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" placeholder="******************" />

            {error ? <p className="text-red-500 text-xs italic">{error}</p> : null }
          </div>
          <div className="flex items-center justify-center">
            <button
              disabled={loading}
              onClick={() => this.login()}
              className="custom bg-blue-500 disabled:bg-gray-300 h-10 w-40 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
              {!loading ? 'Sign In' : <Dots /> }
            </button>
          </div>
        </form>
        <p className="text-center text-gray-500 text-xs">
          <Link style={{textDecoration: 'underline'}} to="/request-reset-password">Forgot password?</Link> <br/> <br/>
          &copy;{moment().format('YYYY')} led&go. All rights reserved.
        </p>
      </div>
    )
  }
}

export default withRouter(LoginForm);
