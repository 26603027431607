const host_prod = 'https://cms.ledandgo.com/api'
const host_dev = 'https://cms.alphamoonbase.de/api'
let host

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    host = host_dev
} else {
    host = host_prod
}

export default host;
